<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { notificationStore } from '~/stores/notificationStore'

const notification = notificationStore()
const { list } = storeToRefs(notification)
</script>

<template>
  <div
    v-show="notification.list.length !== 0"
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <TransitionGroup
      name="notification-transition"
      tag="div"
      class="relative flex w-full flex-col items-center space-y-4 sm:items-end"
    >
      <BaseNotificationItem v-for="item in list" :key="item.id" :item="item" />
    </TransitionGroup>
  </div>
</template>

<style lang="postcss" scoped>
.notification-transition {
  &-enter-active {
    @apply transform transition duration-300 ease-out;
  }
  &-enter-from {
    @apply translate-y-2 opacity-0 sm:translate-x-2 sm:translate-y-0;
  }
  &-enter-to {
    @apply translate-y-0 opacity-100 sm:translate-x-0;
  }
  &-leave-active {
    @apply absolute z-30 transition duration-100 ease-in;
  }
  &-leave-from {
    @apply opacity-100;
  }
  &-leave-to {
    @apply opacity-0;
  }
  &-move {
    @apply transform transition duration-300 ease-in;
  }
}
</style>
