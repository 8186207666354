<script setup lang="ts">
const {
  public: { sentry },
} = useRuntimeConfig()

const testBuild = import.meta.env.VITE_TEST_BUILD === 'true'
const testHeaderString = `Env: ${import.meta.env.VITE_ENVIRONMENT}, Sentry env: ${sentry.env}, Branch: ${import.meta.env.VITE_TEST_BRANCH_NAME}`
</script>

<template>
  <div>
    <div
      v-if="testBuild"
      class="left-0 top-0 flex h-8 w-full items-center justify-between gap-2 bg-green/70 p-2 text-white"
    >
      <div class="flex items-center gap-2">
        <span class="text-xs">{{ testHeaderString }}</span>
        <ClientOnly>
          <LazyBaseDevVersionBar />
        </ClientOnly>
      </div>
      <div class="flex gap-1 self-end text-xs">
        <a
          href="https://www.figma.com/file/kcjnJlor0r82TrXdZjBRhw/Styleguide-ikwoonbeter.nl?type=design&node-id=324-641&mode=design&t=M3ERC1sHCelBxp9d-0"
          class="hover:underline"
        >Huisstijl</a>
        <a
          href="https://www.figma.com/file/aBYuTeZdM6i7iwjc5DHSsQ/ikwoonbeter.nl?type=design&node-id=0-1&mode=design&t=FfICaYHfI0PjyWpH-0"
          class="hover:underline"
        >Design</a>
      </div>
    </div>
  </div>
</template>
